import SEO from '@connected/SEO';
import NewsAndEventsList, { ArticleModel, thumbnail } from '@presentation/NewsAndEventsList';
import SimpleBanner from '@presentation/SimpleBanner';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import { ContentModels } from 'schema/ContentModels';
import { Gatsby } from 'schema/Gatsby';

interface ArticleListProps {
  data: {
    articleList: ContentModels.ContentfulArticleList;
    eventList: ContentModels.ContentfulEventList;
    articles: Gatsby.Connection<ContentModels.ContentfulArticle>;
  };
  pageContext: {
    sortBy: string;
    sortByOrder: 'ASC' | 'DESC';
  };
}

const ArticleList: React.FC<ArticleListProps> = ({ data, pageContext: { sortByOrder } }) => {
  return (
    <>
      <SEO data={data.articleList.metadata} url={data.articleList.url} />
      <SimpleBanner title={data.articleList.title} subtitle={data.articleList.subtitle} />
      <NewsAndEventsList
        currentPage={data.articles.pageInfo?.currentPage ?? 1}
        eventsUrl={data.eventList.url ?? '/'}
        isEvents={false}
        isNews
        items={createItemArray(data.articles.edges?.map((e) => e.node))}
        newsUrl={data.articleList.url ?? '/'}
        noResultsText={data.articleList.noResultsText?.noResultsText ?? ''}
        pageCount={data.articles.pageInfo?.pageCount ?? 1}
        showSortBy
        sortByOrder={sortByOrder}
        hrefBuilder={(pageIndex) => {
          return getPagedUrl(data.articleList.url ?? '/', pageIndex, sortByOrder);
        }}
        onPageChange={({ selected: pageIndex }) => {
          const to = getPagedUrl(data.articleList.url ?? '/', pageIndex + 1, sortByOrder);
          navigate(to);
        }}
        onSortByChange={(order) => {
          const to = getPagedUrl(data.articleList.url ?? '/', 1, order);
          navigate(to);
        }}
      />
    </>
  );
};

export default ArticleList;

function getPagedUrl(baseUrl: string, pageIndex: number, sortByOrder: 'ASC' | 'DESC'): string {
  let url = baseUrl;

  if (sortByOrder === 'ASC') {
    url += '/oldest';
  } else {
    url += '/latest';
  }

  if (pageIndex > 1) {
    url += `/${pageIndex}`;
  }

  return url;
}

function createItemArray(entries?: ContentModels.ContentfulArticle[]): ArticleModel[] {
  return entries?.map(createItemModel) ?? [];
}

function createItemModel(entry: ContentModels.ContentfulArticle): ArticleModel {
  return {
    id: entry.id ?? '',
    date: new Date(entry.date ?? ''),
    thumbnail: thumbnail(entry.thumbnail?.[0]?.public_id),
    title: entry.title ?? '',
    url: entry.url ?? '',
  };
}

export const query = graphql`
  query ArticleListQuery(
    $limit: Int!
    $skip: Int!
    $sortBy: [ContentfulArticleFieldsEnum]!
    $sortByOrder: [SortOrderEnum]!
  ) {
    articleList: contentfulArticleList {
      contentful_id
      metadata {
        ...ContentfulMetadataFragment
      }
      noResultsText {
        noResultsText
      }
      subtitle
      title
      url
    }
    articles: allContentfulArticle(
      limit: $limit
      skip: $skip
      sort: { fields: $sortBy, order: $sortByOrder }
    ) {
      edges {
        node {
          date
          thumbnail
          title
          url
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
      }
    }
    eventList: contentfulEventList {
      url
    }
  }
`;
